// extracted by mini-css-extract-plugin
export var company = "styles-module--company--e5add";
export var companyHeader = "styles-module--companyHeader--55023";
export var companyLogo = "styles-module--companyLogo--2faed";
export var companyMeta = "styles-module--companyMeta--d8771";
export var companyName = "styles-module--companyName--bfc2b";
export var companyNameAndWebsite = "styles-module--companyNameAndWebsite--ae5e3";
export var companyWebsite = "styles-module--companyWebsite--f6863";
export var content = "styles-module--content--e27b3";
export var contentAndSidebar = "styles-module--contentAndSidebar--43a89";
export var cta = "styles-module--cta--47aa0";
export var ctaButton = "styles-module--ctaButton--6f5e1";
export var ctaHeading = "styles-module--ctaHeading--c7ef8";
export var ctaSubheading = "styles-module--ctaSubheading--ca536";
export var getStartedBox = "styles-module--getStartedBox--eccdb";
export var heroImage = "styles-module--heroImage--c2162";
export var introContent = "styles-module--introContent--3ed1b";
export var moreSuccessStories = "styles-module--moreSuccessStories--97a8e";
export var moreSuccessStoriesButton = "styles-module--moreSuccessStoriesButton--ba1f7";
export var moreSuccessStoriesGallery = "styles-module--moreSuccessStoriesGallery--175a0";
export var moreSuccessStoriesHeading = "styles-module--moreSuccessStoriesHeading--f3ab9";
export var moreSuccessStoriesInnerContainer = "styles-module--moreSuccessStoriesInnerContainer--99ad7";
export var moreSuccessStoriesItem = "styles-module--moreSuccessStoriesItem--4175d";
export var moreSuccessStoriesItemImage = "styles-module--moreSuccessStoriesItemImage--9e6f8";
export var moreSuccessStoriesItemTitle = "styles-module--moreSuccessStoriesItemTitle--1c8d2";
export var quoteContent = "styles-module--quoteContent--d0bfc";
export var quoteContentFooter = "styles-module--quoteContentFooter--4859f";
export var quoteContentImage = "styles-module--quoteContentImage--3f0c9";
export var quoteContentName = "styles-module--quoteContentName--4c5b1";
export var quoteContentNameAndTitle = "styles-module--quoteContentNameAndTitle--f59a2";
export var quoteContentQuote = "styles-module--quoteContentQuote--42abc";
export var quoteContentTitle = "styles-module--quoteContentTitle--87ef4";
export var sidebar = "styles-module--sidebar--26c6f";