import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby-plugin-intl";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import { concatClassNames as cn } from "@system42/core";

import {
  ButtonPrimaryLarge,
  ButtonLarge,
  Headline1Sans,
  Headline2Sans,
  Headline3Sans,
  Headline4Sans,
} from "../../system42";
import Layout from "../../layouts/layout";
import { HeaderOverlap } from "../../components/HeaderOverlap";
import { GetStartedBox } from "../../components/GetStartedBox";

import * as styles from "./styles.module.css";

export default function CustomerSuccessStory(props) {
  const { company, image, title, content } = parseApiStory(
    props.data.strapiCustomerSuccessStory
  );

  const otherStories = props.data.allStrapiCustomerSuccessStory.nodes;

  const companyElement = (
    <div className={styles.company}>
      <div className={styles.companyHeader}>
        <GatsbyImage
          className={styles.companyLogo}
          alt={`${company.name} logo`}
          image={company.logo}
        />
        <div className={styles.companyNameAndWebsite}>
          <div className={styles.companyName}>{company.name}</div>
          <a
            target="_blank"
            rel="noreferrer"
            href={company.website}
            className={styles.companyWebsite}
          >
            {company.website.replace(/^https?:\/\//, "")}
          </a>
        </div>
      </div>
      <table className={styles.companyMeta}>
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Industry</td>
            <td>{company.industry}</td>
          </tr>
          <tr>
            <td>Company Size</td>
            <td>{company.size}</td>
          </tr>
          <tr>
            <td>Headquarters</td>
            <td>{company.headquarters}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const ctaElement = (
    <div className={styles.cta}>
      <div className={styles.ctaHeading}>Ready to start?</div>
      <p className={styles.ctaSubheading}>
        Set up in minutes. Get first results in hours.
      </p>
      <ButtonPrimaryLarge
        className={styles.ctaButton}
        customTag={"a"}
        href={"#"}
      >
        Start free trial
      </ButtonPrimaryLarge>
    </div>
  );

  const contentElement = (
    <div className={styles.content}>
      {content.map(({ type, ...props }, index) => {
        switch (type) {
          case "intro":
            return <IntroContent key={index}>{props.text}</IntroContent>;
          case "content":
            return <MarkdownContent key={index}>{props.text}</MarkdownContent>;
          case "quote":
            return (
              <QuoteContent
                key={index}
                name={props.name}
                title={props.title}
                image={props.image}
                companyName={company.name}
              >
                {props.text}
              </QuoteContent>
            );
          default:
            return null;
        }
      })}
    </div>
  );

  const moreSuccessStoriesElement = (
    <section className={styles.moreSuccessStories}>
      <div className={styles.moreSuccessStoriesInnerContainer}>
        <Headline4Sans className={styles.moreSuccessStoriesHeading}>
          More success stories
        </Headline4Sans>
        <div className={styles.moreSuccessStoriesGallery}>
          {otherStories.map((story, index) => (
            <Link
              to={`/customers/${story.url}`}
              key={index}
              className={styles.moreSuccessStoriesItem}
            >
              <GatsbyImage
                alt={`${story.companyName}'s product screenshot`}
                image={story.image.localFile.childImageSharp?.gatsbyImageData}
                className={styles.moreSuccessStoriesItemImage}
              />
              <Headline4Sans className={styles.moreSuccessStoriesItemTitle}>
                {story.title}
              </Headline4Sans>
            </Link>
          ))}
        </div>
        <ButtonLarge
          className={styles.moreSuccessStoriesButton}
          customTag={Link}
          to={"/customers"}
        >
          View all success stories
        </ButtonLarge>
      </div>
    </section>
  );

  return (
    <Layout title={title}>
      <article>
        <HeaderOverlap
          overline={"Customer Success Story"}
          titleWidth={"20em"}
          title={title}
          titleSize="2"
          titleCustomTag="h1"
          staticImage={(className) => (
            <GatsbyImage
              className={cn(className, styles.heroImage)}
              alt={`${company.name}'s product screenshot`}
              image={image}
            />
          )}
        />
        <div className={cn(styles.contentAndSidebar)}>
          {contentElement}
          <aside className={styles.sidebar}>
            {companyElement}
            {ctaElement}
          </aside>
        </div>
        {moreSuccessStoriesElement}
        <div className={styles.getStartedBox}>
          <GetStartedBox
            title={
              <>
                User testing, <strong>made simple.</strong>
              </>
            }
          />
        </div>
      </article>
    </Layout>
  );
}

const parseApiStory = (response) => ({
  title: response.title,
  image: response.image.localFile.childImageSharp?.gatsbyImageData,
  content: response.content.map((content) => {
    const type = content.strapi_component?.replace("cs-content.", "");
    return {
      type,
      ...CONTENT_TYPE_PARSERS[type](content),
    };
  }),
  company: {
    logo: response.companyLogo.localFile.childImageSharp?.gatsbyImageData,
    name: response.companyName,
    website: response.companyWebsite,
    industry: response.companyIndustry,
    size: response.companySize,
    headquarters: response.companyHeadquarters,
  },
});

const CONTENT_TYPE_PARSERS = {
  intro: (response) => ({
    text: response.content.data?.content,
  }),
  content: (response) => ({
    text: response.content.data?.content,
  }),
  quote: (response) => ({
    text: response.quote,
    image: response.person?.image.localFile.childImageSharp?.gatsbyImageData,
    name: response.person?.name,
    title: response.person?.title,
  }),
};

function IntroContent({ children }) {
  return (
    <ReactMarkdown
      components={{
        p: (props) => <p {...props} className={styles.introContent} />,
      }}
      allowedElements={["p", "a"]}
    >
      {children}
    </ReactMarkdown>
  );
}

function MarkdownContent({ children }) {
  return (
    <ReactMarkdown
      components={{
        h1: Headline1Sans,
        h2: Headline2Sans,
        h3: Headline3Sans,
        h4: Headline4Sans,
      }}
    >
      {children}
    </ReactMarkdown>
  );
}

function QuoteContent({ children, name, title, image, companyName }) {
  return (
    <div className={styles.quoteContent}>
      <blockquote className={styles.quoteContentQuote}>{children}</blockquote>
      <div className={styles.quoteContentFooter}>
        <GatsbyImage
          className={styles.quoteContentImage}
          alt={`${companyName}'s representative`}
          image={image}
        />
        <div className={styles.quoteContentNameAndTitle}>
          <div className={styles.quoteContentName}>{name}</div>
          <div className={styles.quoteContentTitle}>{title}</div>
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  query CustomerSuccessStory($strapiId: String!) {
    strapiCustomerSuccessStory(id: { eq: $strapiId }) {
      title
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 1680
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      companyName
      companyWebsite
      companyIndustry
      companySize
      companyHeadquarters
      companyLogo {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 112
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      content {
        ... on STRAPI__COMPONENT_CS_CONTENT_INTRO {
          content {
            data {
              content
            }
          }
          strapi_component
        }
        ... on STRAPI__COMPONENT_CS_CONTENT_CONTENT {
          content {
            data {
              content
            }
          }
          strapi_component
        }
        ... on STRAPI__COMPONENT_CS_CONTENT_QUOTE {
          quote
          person {
            name
            title
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 80
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          strapi_component
        }
      }
    }
    allStrapiCustomerSuccessStory(limit: 3) {
      nodes {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 792
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        url
        title
        companyName
      }
    }
  }
`;
